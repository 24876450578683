import React from 'react';
import Helmet from 'react-helmet';
import {
    HomeHero,
    HomePostList,
    Notfound,
    Default
} from '../components';

import {
    TopContainer
} from '../components/PageStyles/HomeStyles';

const Homepage = ({ location }) => {
    return (
        <Default
            location={location}
        >
            <Helmet>
                <title>404</title>
                <meta name="description" content="Not found"/>
            </Helmet>
            <TopContainer>
                <Notfound/>
                <HomePostList/>
            </TopContainer>
        </Default>
    );
}

export default Homepage;
